 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="事件编号">
				<el-input class="el_input" v-model="form.truck_tord_event_num" placeholder="添加事件编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
			</el-form-item>
		</el-form>


		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="事件编号" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_event_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加时间" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="预录入数量">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tord_total_num}}单</div>
					</template>
				</el-table-column>
				<el-table-column label="待审核数量">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tord_before_check_num}}单</div>
					</template>
				</el-table-column>
				<el-table-column label="已通过数量">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tord_check_pass_num}}单</div>
					</template>
				</el-table-column>
				<el-table-column label="未通过数量">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tord_check_unpass_num}}单</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" width="500px">
					<template slot-scope="scope">
						<div class="tab_line_item">
							<el-input v-model="scope.row.mark">
								<el-button @click="mark_save(scope.row)" slot="append">提交</el-button>
							</el-input>
						</div>
					</template>
				</el-table-column>


				<!-- 操作行 -->
				<el-table-column label="操作" width="125px">
					<template slot-scope="scope">
						<el-button @click="event_tord_list(scope.row,'before_check')" size="mini" type="text">待审核</el-button>
						<el-button @click="event_tord_list(scope.row,'check_pass')" size="mini" type="text">审核通过</el-button>
						<el-button @click="event_tord_list(scope.row,'check_unpass')" size="mini" type="text" style="margin:0">未通过</el-button>
						<el-button @click="event_del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {

				//搜索条件
				form: {
					truck_tord_event_num:'',//添加事件编号搜索
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				}
			}
		},
		created() {
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//提交事件包含的运单
			event_tord_list(item,type){

				//前往不同的运单列表
				switch(type){

					//前往待审核运单列表
					case 'before_check':
						this.$router.push({
							path:'/pages/tord_after_time/tord_before_check',
							query:{
								truck_tord_event_num:item.truck_tord_event_num
							}
						});
						return;
					
					//前往审核已通过运单列表
					case 'check_pass':
						this.$router.push({
							path:'/pages/tord_after_time/tord_check_pass',
							query:{
								truck_tord_event_num:item.truck_tord_event_num
							}
						});
						return;
					
					//前往审核未通过运单列表
					case 'check_unpass':
						this.$router.push({
							path:'/pages/tord_after_time/tord_check_unpass',
							query:{
								truck_tord_event_num:item.truck_tord_event_num
							}
						});
						return;
				}
			},

			//删除提交事件
			event_del(item){

				//提示
				this.$my.other.confirm({
					content:"您的操作是'删除提交事件',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_after_time',
								ctr:'tord_batch_del_by_shipper',
								truck_tord_event_num:item.truck_tord_event_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//保存备注
			mark_save(item){

				if(this.mark_buf[item.id]==item.mark){
					return;
				}

				//是否保存
				this.$my.net.req({
					data:{
						mod:'truck_tord_after_time',
						ctr:'tord_batch_add_event_mark_edit_by_shipper',
						id:item.id,
						mark:item.mark
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type: 'success',
							str: '操作成功'
						})
					}
				})
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_after_time',
						ctr:'tord_batch_add_event_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//备注缓存
						let mark_buf={};

						//预处理
						for(let item of data.list){

							mark_buf[item.id]=item.mark

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
						}

						//渲染
						this.list.data=data.list

						//备注缓存
						this.mark_buf=mark_buf;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
</style>